import { NgModule } from "@angular/core";
import { SharedModule } from "../shared-module/shared.module";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MasterSalesManagerWriteupComponent, SalesManagerWriteupComponent, SalesManagerWriteupsListComponent, } from "./pages";
import { SalesManagerRoutingModule } from "./sales-manager-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BuyerFormComponent, CashFormComponent, FinanceFormComponent, LeaseFormComponent, SalesManagerFormComponent, SalesManagerWriteupControlsComponent, SalesManagerWriteupHeaderComponent, SimpleInputComponent, SmwInsuranceProductsFormComponent, SmwRebatesFormComponent, StaffFormComponent, VehicleInfoFormComponent, VehiclePriceFormComponent, VehicleTradeInFormComponent, } from "./components";
import { CreditTierSelectComponent } from "./components/forms/credit-tier-select/credit-tier-select.component";
import { VehicleNeedsFormComponent } from "./components/forms/vehicle-needs-form/vehicle-needs-form.component";
import { AccessoriesItemizedComponent } from "./components/forms/accessories-itemized/accessories-itemized.component";
import { InsuranceInfoComponent } from "./components/forms/insurance-info/insurance-info.component";
import { TradeInfoComponent } from "./components/forms/trade-info/trade-info.component";
import { TradeOwnerComponent } from "./components/forms/trade-owner/trade-owner.component";
import { InsuranceProductSelectorComponent } from "./components/insurance-product-selector/insurance-product-selector.component";
import { LenderSelectComponent } from "./components/forms/lender-select/lender-select.component";
import { SimpleInputDropdownComponent } from './components/forms/simple-input-dropdown/simple-input-dropdown.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { AlternateVsaDialogComponent } from './components/dialogs/alternate-vsa-dialog/alternate-vsa-dialog.component';
import { MatListModule } from "@angular/material/list";

@NgModule({
  declarations: [
    StaffFormComponent,
    SalesManagerFormComponent,
    SalesManagerWriteupComponent,
    SalesManagerWriteupsListComponent,
    BuyerFormComponent,
    FinanceFormComponent,
    LeaseFormComponent,
    CashFormComponent,
    VehicleInfoFormComponent,
    VehiclePriceFormComponent,
    VehicleTradeInFormComponent,
    BuyerFormComponent,
    SalesManagerWriteupControlsComponent,
    SalesManagerWriteupHeaderComponent,
    SmwInsuranceProductsFormComponent,
    SmwRebatesFormComponent,
    MasterSalesManagerWriteupComponent,
    SimpleInputComponent,
    CreditTierSelectComponent,
    VehicleNeedsFormComponent,
    AccessoriesItemizedComponent,
    InsuranceInfoComponent,
    TradeInfoComponent,
    TradeOwnerComponent,
    InsuranceProductSelectorComponent,
    LenderSelectComponent,
    SimpleInputDropdownComponent,
    AlternateVsaDialogComponent
  ],
  entryComponents: [AlternateVsaDialogComponent],
  imports: [
    CommonModule,
    SalesManagerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    SharedModule,
    DragDropModule,
    MatButtonModule,
    MatDialogModule
  ],
  exports: []
})
export class SalesManagerModule {}

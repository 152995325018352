import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ConfigDealer, ConfigFinance, ConfigIncentives, InsuranceProduct, NewResiduals, ResidualValue } from "../models";
import { map } from "rxjs/operators";

const api = "/v1/config";
const routes = {
  getInsurance: `${api}/getinsurance`,
  setInsurance: `${api}/setinsurance`,
  getFinance: `${api}/getfinance`,
  setFinance: `${api}/setfinance`,
  getDealer: `${api}/getdealer`,
  setDealer: `${api}/setdealer`,
  getNewResiduals: `${api}/getnewresiduals`,
  setNewResiduals: `${api}/setnewresiduals`,
  getCertUsedResiduals: `${api}/getcertusedresiduals`,
  setCertUsedResiduals: `${api}/setcertusedresiduals`,
  getIncentives: `${api}/getincentives`,
  getForms: `${api}/getforms`,
  setIncentives: `${api}/setincentives`,
  ethosImporter: `${api}/ethosImporter`,
  pbsImporter: `${api}/pbsImporter`,
  pbsImporterForVin: `${api}/pbsImporterForVin`,
  pbsUserImporter: `${api}/pbsUserImporter`,
};

@Injectable({
  providedIn: "root"
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  // HTTP

  getInsuranceProducts(): Observable<any> {
    return this.http.get(routes.getInsurance);
  }

  setInsuranceProducts(insuranceProducts: Array<InsuranceProduct>): Observable<any> {
    return this.http.post(routes.setInsurance, {insuranceProducts});
  }

  getConfigFinance(): Observable<any> {
    return this.http.get(routes.getFinance);
  }

  setConfigFinance(financeConfig: ConfigFinance): Observable<any> {
    return this.http.post(routes.setFinance, financeConfig);
  }

  getConfigDealer(): Observable<any> {
    return this.http.get(routes.getDealer);
  }

  setConfigDealer(configDealer: ConfigDealer) {
    return this.http.post(routes.setDealer, configDealer);
  }

  getNewResiduals(): Observable<any> {
    return this.http.get(routes.getNewResiduals);
  }

  setNewResiduals(newResiduals: NewResiduals[]) {
    return this.http.post(routes.setNewResiduals, {newResiduals});
  }

  getCertUsedResiduals(): Observable<any> {
    return this.http.get(routes.getCertUsedResiduals);
  }

  setCertUsedResiduals(residualValues: ResidualValue[]) {
    return this.http.post(routes.setCertUsedResiduals, {residualValues});
  }

  getIncentives(): Observable<any> {
    return this.http.get(routes.getIncentives).pipe(
      map((incentives: ConfigIncentives) => {
        incentives.incentiveGroups = incentives.incentiveGroups || [];
        return incentives;
      })
    );
  }

  getForms(): Observable<any> {
    return this.http.get(routes.getForms);
  }

  setIncentives(configIncentives: ConfigIncentives) {
    return this.http.post(routes.setIncentives, configIncentives);
  }

  runEthosImporter() {
    return this.http.get(routes.ethosImporter);
  }

  runPbsImporter() {
    return this.http.get(routes.pbsImporter);
  }

  runPbsImporterForVin(vin: string) {
    return this.http.post(routes.pbsImporterForVin, {vin});
  }
  
  runPbsUserImporter() {
    return this.http.get(routes.pbsUserImporter);
  }
}

import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import * as fromRoot from "../../../clearpath-module/store/state/app";
import { PubNubAngular } from "pubnub-angular2";
import { PubsubMessages } from "src/app/app.config";
import * as appActions from "../../../clearpath-module/store/actions/app.actions";
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: "root"
})
export class PubnubService {
  constructor(
    private pubnub: PubNubAngular,
    private store: Store<fromRoot.AppState>
  ) {
    this.pubnub.init({
      publishKey: environment.pubNubPubKey,
      subscribeKey: environment.pubNubSubKey,
      uuid: environment.pubNubUserId
    });
    this.pubnub.addListener({
      status: st => {
        console.log("Pub Nub Status:", st);
      }
    });
    this.pubnub.getError(environment.salesQueueChannel, err => {
      console.error(err);
    });
  }

  get Messages() {
    return PubsubMessages;
  }

  dispatchPubsubMessage({messageName, dealId}: { messageName: PubsubMessages, dealId: string }) {
    console.log("Dispatching Pubsub Message:", messageName, dealId);
    this.store.dispatch(appActions.pubsubMessage({messageName, dealId}));
  }

  salesQueueChanged(callback) {
    this.pubnub.subscribe({
      channels: [environment.salesQueueChannel],
      triggerEvents: true
    });
    // tslint:disable-next-line: only-arrow-functions
    this.pubnub.getMessage(environment.salesQueueChannel, function (msg) {
      callback();
    });
  }

  watchMsgChannel() {
    this.pubnub.subscribe({
      channels: [environment.msgChannel],
      triggerEvents: true
    });
    const self = this;
    this.pubnub.getMessage(environment.msgChannel, function ({message}) {
      if (message.length == 2 && message[ 0 ].startsWith('{deal')) {
        //console.log("Deal Status Message received");
        self.dispatchPubsubMessage({messageName: message[ 0 ], dealId: message[ 1 ]});
      }
    });
  }
}
